import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import * as cssVars from "../../styles/variables.module.css"

import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children }) => {
  return (
    <div className={[cssVars.root].join(" ")}>
      <Header />
      <Container fluid className="p-0 m-0 min-vh-100">
        <main>{children}</main>
      </Container>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
