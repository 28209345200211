import React from "react"
import { Link } from "gatsby"
import { FaInstagram, FaYoutube, FaFacebook } from "react-icons/fa"
import { Container } from "react-bootstrap"

import { blogConfig } from "../../blogConfig"

const Instagram = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlInstagram}
    className="text-decoration-none text-white-50"
  >
    <FaInstagram size={24} className="mx-2" />
  </a>
)

const Youtube = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlYoutube}
    className="text-decoration-none text-white-50"
  >
    <FaYoutube size={24} className="mx-2" />
  </a>
)

const Facebook = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlFacebook}
    className="text-decoration-none text-white-50"
  >
    <FaFacebook size={24} className="mx-2" />
  </a>
)

const Footer = () => (
  <footer className="py-2 mt-5 text-white-50 bg-identity flex-shrink-0">
    <Container className="d-flex justify-content-between">
      <Container className="d-flex justify-content-start align-items-center">
        <Instagram />
        <Youtube />
        <Facebook />
      </Container>
      <Container className="text-right">

        <small>
          <Link className="text-decoration-none text-white-50" to="/contato">
            Contato
          </Link>
        </small>
      </Container>
    </Container>
  </footer>
)

export default Footer
